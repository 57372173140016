$color-opacity-1: rgba(255, 255, 255, 0.1);
$white: #ffffff;
$black: #0B0D1A;
$deep: #141623;
$bg: #373646;
$light: #D2D6E1;
$bright: #BFB1B1;

$warning: #F0A93A;
$danger: #FF5500;

$success: #447A63;
$success1: #8ad66c;
$success2: #54af2f;