@import './assets/style/variable.scss';

.app-page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  header {
    .logo {
      width: 2.2rem;
    }
  }
}